
import { defineComponent, ref, onMounted } from 'vue'
import { NotificationOutlined, SearchOutlined } from '@ant-design/icons-vue'

import authServices from './services/auth'
import commonServices from './services/common'
import router from './router'
import IProfile from './interface/profile'

export default defineComponent({
  setup () {
    const loginAs = ref(new URLSearchParams(window.location.search).get('loginAs'))
    const profile = ref<IProfile>(commonServices.getCurrentProfile())
    const clientLogo = ref<string>(localStorage.getItem('clientLogo'))
    const logout = () => {
      authServices.logout()
      router.push('/login')
    }
    const updateMenu = () => {
      profile.value = commonServices.getCurrentProfile()
      clientLogo.value = localStorage.getItem('clientLogo')
      console.log('clientLogo.value', clientLogo.value)
    }
    onMounted(() => {
      if (loginAs.value) {
        loginAs.value = '?loginAs=' + loginAs.value
      } else {
        loginAs.value = ''
      }
    })
    return {
      selectedKeys: ref(['1']),
      collapsed: ref(false),
      logout,
      profile,
      updateMenu,
      clientLogo,
      loginAs
    }
  },
  components: {
    // NotificationOutlined,
    // SearchOutlined
  }
})
