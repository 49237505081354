import axios from 'axios'

export default {
  login (data: any) {
    return axios.post(process.env.VUE_APP_USER_LOGIN, data)
  },

  forgotPassword (data: any) {
    return axios.post(process.env.VUE_APP_AUTH_FORGOT_PASSWORD, data)
  },

  changePassword (data: any) {
    return axios.post(process.env.VUE_APP_AUTH_CHANGE_PASSWORD, data)
  },

  logout () {
    delete localStorage.accessToken
    delete localStorage.profile
    delete localStorage.subscription
  },

  loggedIn () {
    return !!localStorage.accessToken
  }
}
