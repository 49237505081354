import { createApp } from 'vue'
import { Layout, Row, Col, Select, Input, InputNumber, Table, Tabs, Tag, Button, Breadcrumb, PageHeader, Pagination, Form, Menu, Avatar, Image, Checkbox, Radio, Progress, Upload, Modal, Steps, Empty, Divider, DatePicker, TimePicker, Switch, Collapse, Drawer, List, Tooltip, Popconfirm, Calendar, Popover } from 'ant-design-vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'

import 'ant-design-vue/dist/antd.css'

createApp(App)
  .use(store)
  .use(router)
  .use(Row)
  .use(Col)
  .use(Layout)
  .use(Select)
  .use(Input)
  .use(InputNumber)
  .use(Table)
  .use(Tabs)
  .use(Tag)
  .use(Button)
  .use(Breadcrumb)
  .use(PageHeader)
  .use(Pagination)
  .use(Form)
  .use(Menu)
  .use(Avatar)
  .use(Progress)
  .use(Image)
  .use(Radio)
  .use(Checkbox)
  .use(Upload)
  .use(Modal)
  .use(Steps)
  .use(Empty)
  .use(Divider)
  .use(DatePicker)
  .use(TimePicker)
  .use(Switch)
  .use(Collapse)
  .use(Drawer)
  .use(List)
  .use(Tooltip)
  .use(Popconfirm)
  .use(Calendar)
  .use(Popover)
  .use(VueAxios, axios)
  .mount('#app')

axios.defaults.timeout = 200000
axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.interceptors.request.use((config: any) => {
  const token = localStorage.getItem('accessToken')
  if (token) {
    config.headers.common.token = token
  }
  return config
}, (error: any) => {
  return Promise.reject(error)
})
axios.interceptors.response.use((response: any) => {
  return response
}, (error: any) => {
  return Promise.reject(error)
})
