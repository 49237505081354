export default {
  getCurrentProfile () {
    const urlParams = new URLSearchParams(window.location.search)
    const loginAs = urlParams.get('loginAs')
    const profile = JSON.parse(localStorage.getItem('profile'))
    if (loginAs && profile.profile_type.slug === 'agency') {
      if (loginAs === 'client' || loginAs === 'candidate') {
        return JSON.parse(localStorage.getItem('loginAsProfile'))
      } else {
        return profile
      }
    } else if (loginAs && profile.profile_type.slug === 'client') {
      if (loginAs === 'candidate') {
        return JSON.parse(localStorage.getItem('loginAsProfile'))
      } else {
        return profile
      }
    } else {
      return profile
    }
  },

  getCurrentSubscription () {
    const urlParams = new URLSearchParams(window.location.search)
    const loginAs = urlParams.get('loginAs')
    const profile = JSON.parse(localStorage.getItem('profile'))
    const subscription = JSON.parse(localStorage.getItem('subscription'))
    if (loginAs && profile.profile_type.slug === 'agency') {
      if (loginAs === 'client') {
        return JSON.parse(localStorage.getItem('loginAsSubscription'))
      } else {
        return subscription
      }
    } else {
      return subscription
    }
  },

  getClientId () {
    const profile = JSON.parse(localStorage.getItem('profile'))
    const urlParams = new URLSearchParams(window.location.search)
    const loginAs = urlParams.get('loginAs')
    if (profile.profile_type.slug === 'system_users') {
      return localStorage.getItem('clientId')
    } else if (loginAs && profile.profile_type.slug === 'agency') {
      if (loginAs === 'client') {
        return JSON.parse(localStorage.getItem('loginAsProfile'))._id
      }
    } else {
      return profile._id
    }
  }
}
