import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import commonServices from '../services/common'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Login.vue'),
    meta: {
      guest: true,
      title: process.env.VUE_APP_PAGE_TITLE
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      guest: true,
      title: process.env.VUE_APP_PAGE_TITLE
    }
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import('../views/Signup.vue'),
    meta: {
      guest: true,
      title: process.env.VUE_APP_PAGE_TITLE
    }
  },
  {
    path: '/no_access',
    name: 'No Access',
    component: () => import('../views/NoAccess.vue'),
    meta: {
      requiresAuth: true,
      title: process.env.VUE_APP_PAGE_TITLE
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/agency/dashboard/Dashboard.vue'),
    meta: {
      requiresAuth: true,
      isAgency: true,
      title: process.env.VUE_APP_PAGE_TITLE
    }
  },
  {
    path: '/sample-payment',
    name: 'PaymentSample',
    component: () => import('../views/PaymentSample.vue'),
    meta: {
      requiresAuth: true,
      title: process.env.VUE_APP_PAGE_TITLE
    }
  },
  {
    path: '/sample-subscription',
    name: 'SubscriptionSample',
    component: () => import('../views/SubscriptionSample.vue'),
    meta: {
      requiresAuth: true,
      title: process.env.VUE_APP_PAGE_TITLE
    }
  },
  {
    path: '/category',
    component: () => import('../views/SubRoute.vue'),
    children: [
      {
        path: '/category',
        name: 'Category List',
        component: () => import('../views/agency/category/List.vue'),
        meta: {
          requiresAuth: true,
          isAgency: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      },
      {
        path: '/category/add',
        name: 'Category Add',
        component: () => import('../views/agency/category/Add.vue'),
        meta: {
          requiresAuth: true,
          isAgency: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      },
      {
        path: '/category/add/:id/:sub',
        name: 'Sub Category Add',
        component: () => import('../views/agency/category/Add.vue'),
        meta: {
          requiresAuth: true,
          isAgency: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      },
      {
        path: '/category/edit/:id',
        name: 'Category Edit',
        component: () => import('../views/agency/category/Edit.vue'),
        meta: {
          requiresAuth: true,
          isAgency: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      },
      {
        path: '/category/edit/:id/:sub',
        name: 'Sub Category Edit',
        component: () => import('../views/agency/category/Edit.vue'),
        meta: {
          requiresAuth: true,
          isAgency: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      }
    ]
  },
  {
    path: '/users',
    component: () => import('../views/SubRoute.vue'),
    children: [
      {
        path: '/users',
        name: 'User Management',
        component: () => import('../views/agency/user/users/List.vue'),
        meta: {
          requiresAuth: true,
          isAgency: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      },
      {
        path: '/users/add',
        name: 'New User',
        component: () => import('../views/agency/user/users/Add.vue'),
        meta: {
          requiresAuth: true,
          isAgency: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      },
      {
        path: '/users/privilege',
        name: 'User Privileges',
        component: () => import('../views/agency/user/privilege/List.vue'),
        meta: {
          requiresAuth: true,
          isAgency: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      },
      {
        path: '/users/privilege/add',
        name: 'New Privilege',
        component: () => import('../views/agency/user/privilege/Add.vue'),
        meta: {
          requiresAuth: true,
          isAgency: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      },
      {
        path: '/users/roles',
        name: 'Role Management',
        component: () => import('../views/agency/user/roles/List.vue'),
        meta: {
          requiresAuth: true,
          isAgency: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      },
      {
        path: '/users/roles/add',
        name: 'New Role',
        component: () => import('../views/agency/user/roles/Add.vue'),
        meta: {
          requiresAuth: true,
          isAgency: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      }
    ]
  },
  {
    path: '/manage-payments',
    name: 'Manage Payment',
    component: () => import('../views/agency/payment/Subscription.vue'),
    meta: {
      requiresAuth: true,
      isAgency: true,
      title: process.env.VUE_APP_PAGE_TITLE
    }
  },
  {
    path: '/candidates',
    component: () => import('../views/SubRoute.vue'),
    children: [
      {
        path: '/candidates',
        name: 'Candidates List',
        component: () => import('../views/agency/candidates/List.vue'),
        meta: {
          requiresAuth: true,
          isAgency: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      },
      {
        path: '/candidates/add',
        name: 'New Candidate',
        component: () => import('../views/agency/candidates/Add.vue'),
        meta: {
          requiresAuth: true,
          isAgency: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      }
    ]
  },
  {
    path: '/course',
    component: () => import('../views/SubRoute.vue'),
    children: [
      {
        path: '/course',
        name: 'Course',
        component: () => import('../views/agency/course/List.vue'),
        meta: {
          requiresAuth: true,
          isAgency: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      },
      {
        path: '/course/add/:id?',
        name: 'Course Add',
        component: () => import('../views/agency/course/Add.vue'),
        meta: {
          requiresAuth: true,
          isAgency: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      },
      {
        path: '/course/edit/:id',
        name: 'Course Edit',
        component: () => import('../views/agency/course/Edit.vue'),
        meta: {
          requiresAuth: true,
          isAgency: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      },
      {
        path: '/course/:id/chapter',
        name: 'List Chapter',
        component: () => import('../views/agency/chapter/List.vue'),
        meta: {
          requiresAuth: true,
          isAgency: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      },
      {
        path: '/course/:id/subcourse',
        name: 'List Sub Course',
        component: () => import('../views/agency/subcourse/List.vue'),
        meta: {
          requiresAuth: true,
          isAgency: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      },
      {
        path: '/course/:courseId/chapter/:chapterId',
        name: 'View Chapter',
        component: () => import('../views/agency/chapter/View.vue'),
        meta: {
          requiresAuth: true,
          isAgency: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      },
      {
        path: '/course/:courseId/chapter/:chapterId/quiz/:quizId',
        name: 'Quiz Questions',
        component: () => import('../views/agency/question/List.vue'),
        meta: {
          requiresAuth: true,
          isAgency: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      }
    ]
  },
  {
    path: '/clients',
    component: () => import('../views/SubRoute.vue'),
    children: [
      {
        path: '/clients',
        name: 'Client List',
        component: () => import('../views/agency/client/List.vue'),
        meta: {
          requiresAuth: true,
          isAgency: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      },
      {
        path: '/clients/add',
        name: 'Client Add',
        component: () => import('../views/agency/client/Add.vue'),
        meta: {
          requiresAuth: true,
          isAgency: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      },
      {
        path: '/clients/edit/:id',
        name: 'Client Edit',
        component: () => import('../views/agency/client/Edit.vue'),
        meta: {
          requiresAuth: true,
          isAgency: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      },
      {
        path: '/clients/view/:id',
        name: 'View Client',
        component: () => import('../views/agency/client/View.vue'),
        meta: {
          requiresAuth: true,
          isAgency: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      }
    ]
  },
  {
    path: '/keys',
    name: 'Key',
    component: () => import('../views/agency/settings/Key.vue'),
    meta: {
      requiresAuth: true,
      isAgency: true,
      title: process.env.VUE_APP_PAGE_TITLE
    }
  },
  {
    path: '/sys-settings',
    name: 'Settings',
    component: () => import('../views/agency/settings/SysSettings.vue'),
    meta: {
      requiresAuth: true,
      isAgency: true,
      title: process.env.VUE_APP_PAGE_TITLE
    }
  },
  {
    path: '/change-password',
    name: 'New Password',
    component: () => import('../views/candidate/settings/ChangePassword.vue'),
    meta: {
      requiresAuth: true,
      isAgency: true,
      title: process.env.VUE_APP_PAGE_TITLE
    }
  },
  // client routes
  {
    path: '/client/dashboard',
    name: 'Client Dashboard',
    component: () => import('../views/client/dashboard/Dashboard.vue'),
    meta: {
      requiresAuth: true,
      isClient: true,
      title: process.env.VUE_APP_PAGE_TITLE
    }
  },
  {
    path: '/client/candidates',
    component: () => import('../views/SubRoute.vue'),
    children: [
      {
        path: '/client/candidates',
        name: 'Candidates List',
        component: () => import('../views/client/candidates/List.vue'),
        meta: {
          requiresAuth: true,
          isClient: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      },
      {
        path: '/client/candidates/import',
        name: 'Import Candidates',
        component: () => import('../views/client/candidates/Import.vue'),
        meta: {
          requiresAuth: true,
          isClient: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      },
      {
        path: '/client/candidates/add',
        name: 'New Candidate',
        component: () => import('../views/client/candidates/Add.vue'),
        meta: {
          requiresAuth: true,
          isClient: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      },
      {
        path: '/client/candidates/edit/:id',
        name: 'Edit Candidate',
        component: () => import('../views/client/candidates/Edit.vue'),
        meta: {
          requiresAuth: true,
          isClient: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      },
      {
        path: '/client/candidate_group',
        name: 'Candidate Group List',
        component: () => import('../views/client/candidates/GroupList.vue'),
        meta: {
          requiresAuth: true,
          isClient: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      },
      {
        path: '/client/candidate_group/add/:id',
        name: 'Add New Candidate Group',
        component: () => import('../views/client/candidates/AddGroupCandidate.vue'),
        meta: {
          requiresAuth: true,
          isClient: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      },
      {
        path: '/client/candidates/add/:group',
        name: 'New Group Candidate',
        component: () => import('../views/client/candidates/Add.vue'),
        meta: {
          requiresAuth: true,
          isClient: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      }
    ]
  },
  {
    path: '/client/courses',
    component: () => import('../views/SubRoute.vue'),
    children: [
      {
        path: '/client/courses',
        name: 'Course List',
        component: () => import('../views/client/courses/List.vue'),
        meta: {
          requiresAuth: true,
          isClient: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      },
      {
        path: '/client/group_courses',
        name: 'Group Course List',
        component: () => import('../views/client/courses/Grouplist.vue'),
        meta: {
          requiresAuth: true,
          isClient: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      },
      {
        path: '/client/group_course/add/:id',
        name: 'Add New Group Course',
        component: () => import('../views/client/courses/AddGroupCourse.vue'),
        meta: {
          requiresAuth: true,
          isClient: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      }
    ]
  },
  {
    path: '/client/certificate',
    component: () => import('../views/SubRoute.vue'),
    children: [
      {
        path: '/client/certificate',
        name: 'Certificate',
        component: () => import('../views/client/certificate/NameList.vue'),
        meta: {
          requiresAuth: true,
          isClient: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      },
      {
        path: '/client/certificate/list/:id',
        name: 'Certificate List',
        component: () => import('../views/client/certificate/List.vue'),
        meta: {
          requiresAuth: true,
          isClient: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      }
    ]
  },
  {
    path: '/client/matrix',
    name: 'Matrix',
    component: () => import('../views/client/matrix.vue'),
    meta: {
      requiresAuth: true,
      isClient: true,
      title: process.env.VUE_APP_PAGE_TITLE
    }
  },
  {
    path: '/client/subscription',
    name: 'Subscription',
    component: () => import('../views/client/subscription/payment.vue'),
    meta: {
      requiresAuth: true,
      isClient: true,
      title: process.env.VUE_APP_PAGE_TITLE
    }
  },
  {
    path: '/client/settings/personal-details',
    name: 'Client Settings',
    component: () => import('../views/candidate/settings/PersonalDetails.vue'),
    meta: {
      requiresAuth: true,
      isClient: true,
      title: process.env.VUE_APP_PAGE_TITLE
    }
  },
  {
    path: '/client/settings/change-password',
    name: 'Update Password',
    component: () => import('../views/candidate/settings/ChangePassword.vue'),
    meta: {
      requiresAuth: true,
      isClient: true,
      title: process.env.VUE_APP_PAGE_TITLE
    }
  },
  {
    path: '/client/settings/emails',
    name: 'Email Settings',
    component: () => import('../views/client/settings/Email.vue'),
    meta: {
      requiresAuth: true,
      isClient: true,
      title: process.env.VUE_APP_PAGE_TITLE
    }
  },
  {
    path: '/client/settings/apps',
    name: 'App Settings',
    component: () => import('../views/client/settings/AppSettings.vue'),
    meta: {
      requiresAuth: true,
      isClient: true,
      title: process.env.VUE_APP_PAGE_TITLE
    }
  },
  {
    path: '/client/payment-success',
    name: 'Payment Success',
    component: () => import('../views/client/subscription/PaymentSuccess.vue'),
    meta: {
      requiresAuth: true,
      isClient: true,
      title: process.env.VUE_APP_PAGE_TITLE
    }
  },
  {
    path: '/client/payment-failed',
    name: 'Payment Failed',
    component: () => import('../views/client/subscription/PaymentFailed.vue'),
    meta: {
      requiresAuth: true,
      isClient: true,
      title: process.env.VUE_APP_PAGE_TITLE
    }
  },
  // Candidate Routes
  {
    path: '/candidate/course',
    component: () => import('../views/SubRoute.vue'),
    children: [
      {
        path: '/candidate/course',
        name: 'Available Course',
        component: () => import('../views/candidate/course/List.vue'),
        meta: {
          requiresAuth: true,
          isCandidate: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      },
      {
        path: '/candidate/course/view/:id',
        name: 'Available Course View',
        component: () => import('../views/candidate/course/View.vue'),
        meta: {
          requiresAuth: true,
          isCandidate: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      },
      {
        path: '/candidate/course/:id/subcourse',
        name: 'Available Sub Course',
        component: () => import('../views/candidate/subcourse/List.vue'),
        meta: {
          requiresAuth: true,
          isCandidate: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      }
    ]
  },
  {
    path: '/candidate/certificate',
    component: () => import('../views/SubRoute.vue'),
    children: [
      {
        path: '/candidate/certificate',
        name: 'Available Certificates',
        component: () => import('../views/candidate/certificate/List.vue'),
        meta: {
          requiresAuth: true,
          isCandidate: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      }
    ]
  },
  {
    path: '/candidate/settings',
    component: () => import('../views/SubRoute.vue'),
    children: [
      {
        path: '/candidate/settings/personal-details',
        name: 'Personal Details',
        component: () => import('../views/candidate/settings/PersonalDetails.vue'),
        meta: {
          requiresAuth: true,
          isCandidate: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      },
      {
        path: '/candidate/settings/change-password',
        name: 'Change Password',
        component: () => import('../views/candidate/settings/ChangePassword.vue'),
        meta: {
          requiresAuth: true,
          isCandidate: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      },
      {
        path: '/candidate/settings/help-center',
        name: 'Help Center',
        component: () => import('../views/candidate/settings/HelpCenter.vue'),
        meta: {
          requiresAuth: true,
          isCandidate: true,
          title: process.env.VUE_APP_PAGE_TITLE
        }
      }
    ]
  },
  {
    path: '/:catchAll(.*)',
    name: 'Page Not Found',
    component: () => import('../views/404.vue'),
    meta: {
      requiresAuth: false,
      title: process.env.VUE_APP_PAGE_TITLE
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const defaultTitle = ''

router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? to.meta.title as string : defaultTitle

  // Append Map API script
  if (!document.getElementById('google-map-api')) {
    const map = document.createElement('script')
    map.setAttribute('src', 'https://maps.googleapis.com/maps/api/js?key=' + process.env.VUE_APP_GOOGLE_MAP_API_KEY + '&libraries=places')
    map.setAttribute('type', 'text/javascript')
    map.setAttribute('id', 'google-map-api')
    document.head.appendChild(map)
  }
  // Append favicon
  if (!document.getElementById('favicon')) {
    const link = document.createElement('link')
    link.setAttribute('type', 'image/x-icon')
    link.setAttribute('rel', 'shortcut icon')
    link.setAttribute('href', process.env.VUE_APP_FAVICON_URL)
    link.setAttribute('id', 'favicon')
    document.head.appendChild(link)
  }

  // Append stripe URL
  if (!document.getElementById('stripe-client-id')) {
    const stripe = document.createElement('script')
    stripe.setAttribute('src', 'https://www.paypal.com/sdk/js?currency=GBP&client-id=' + process.env.VUE_APP_STRIPE_CLIENT_ID)
    stripe.setAttribute('type', 'text/javascript')
    stripe.setAttribute('id', 'stripe-client-id')
    document.head.appendChild(stripe)
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('accessToken') == null) {
      next({ name: 'Login' })
    } else {
      const profile = commonServices.getCurrentProfile()
      if (to.matched.some(record => record.meta.isAgency)) {
        if (profile.profile_type.slug === 'agency') {
          next()
        } else {
          next({ name: 'No Access' })
        }
      } else if (to.matched.some(record => record.meta.isClient)) {
        if (profile.profile_type.slug === 'client' || profile.profile_type.slug === 'system_users') {
          if (profile.status === 'unpaid' && to.matched.some(record => record.name !== 'Subscription') && to.matched.some(record => record.name !== 'Payment Success') && to.matched.some(record => record.name !== 'Payment Failed')) {
            next({ name: 'Subscription' })
          } else {
            next()
          }
        } else {
          next({ name: 'No Access' })
        }
      } else if (to.matched.some(record => record.meta.isCandidate)) {
        if (profile.profile_type.slug === 'candidate') {
          next()
        } else {
          next({ name: 'No Access' })
        }
      } else {
        next()
      }
    }
  } else {
    next()
  }
})

export default router
